import React from "react"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Constants from "../config/constants"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const styles = makeStyles(theme => {
  return {
    container: {
      paddingTop: theme.spacing(10)
    }
  }
})

const ThankYou = () => {
  const classes = styles()
  return (
    <Layout>
      <Seo noIndex/>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Container maxWidth="xs">
              <StaticImage
                src='../images/logo.png'
                alt={`${Constants.siteMetadata.defaultTitle}`}
                placeholder="tracedSVG"
                loading='eager'
                quality='100'
              />
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="p" align="center">
              Thank you!
            </Typography>
            <Typography variant="body1" component="p" align="center">
              We will contact you soon.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ThankYou